<form [formGroup]="passForm">
  <p-table
    [value]="flightInfo"
    scrollHeight="380px"
    scrollWidth="700px"
    responsiveLayout="scroll"
    class="order-details-tab"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>
          {{
            'order-details.header.client-order' | translate: { orderId: orderExternalIdentifier }
          }}
        </th>
        <th>
          {{
            'dialog.general-delivery.boarding-pass-table.headers.client-info'
              | translate
              | uppercase
          }}
        </th>
        <th>
          {{
            'dialog.general-delivery.boarding-pass-table.headers.boarding-pass-info'
              | translate
              | uppercase
          }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [ngClass]="compare(boardingPassQrInfo[i].value, row.value, row.key)">
        <td class="text-left bold border-left-white">
          <p>
            {{ 'dialog.general-delivery.boarding-pass-table.columns.' + row.label | translate }}
          </p>
        </td>
        <td class="text-left border-left-white">
          <span *ngIf="isDate(row.value); else noDate">
            {{ row.value | date }}
          </span>
          <ng-template #noDate>
            <span>{{ row.value }}</span>
          </ng-template>
        </td>

        <td class="boarding-qr-pass-info">
          <span *ngIf="isDate(boardingPassQrInfo[i].value); else noQrDate">
            {{ boardingPassQrInfo[i].value | date }}
          </span>
          <ng-template #noQrDate>
            <span *ngIf="boardingPassQrInfo[i].value !== 'err'; else displayError">
              {{ boardingPassQrInfo[i].value }}</span
            >
            <ng-template #displayError>
              <span *ngIf="!bypassScan && this.isComparableData(row.key)">{{
                'scan_error' | translate
              }}</span>
              <input
                [class.error]="
                  passForm.get(row.key)?.invalid &&
                  (passForm.get(row.key)?.dirty || passForm.get(row.key)?.touched)
                "
                pInputText
                *ngIf="bypassScan && this.isComparableData(row.key)"
                type="text"
                [placeholder]="row.key === 'flightDate' ? dateInputFormat : ''"
                [formControlName]="row.key"
              />
            </ng-template>
          </ng-template>
          <i class="pi-check-circle pi"></i>
          <i class="pi-info-circle pi"></i>
          <button *ngIf="scanDone === false && i === 3" pButton (click)="toggleScan()">
            {{ 'dialog.general-delivery.boarding-pass-table.buttons.scan' | translate }}
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <button
    [ngStyle]="{ display: scanDone === true ? 'block' : 'none' }"
    class="link redoscan-btn"
    (click)="toggleScan(); deleteInfo()"
  >
    {{ 'dialog.general-delivery.scan.redo-scan' | translate }}
  </button>
  <div class="scanner-wrapper" [hidden]="!scanDisplay">
    <div class="p-dialog">
      <div class="p-dialog-content">
        <div class="p-dialog-header">
          <select class="select" (change)="onDeviceSelectChange($event)">
            <option value="" [selected]="!currentDevice">
              {{ 'dialog.general-delivery.scan.no-device-selected' | translate }}
            </option>
            <option
              *ngFor="let device of availableDevices"
              [value]="device.deviceId"
              [selected]="currentDevice && device.deviceId === currentDevice.deviceId"
            >
              {{ device.label }}
            </option>
          </select>
          <div class="p-dialog-header-icons">
            <button
              (click)="toggleScan()"
              type="button"
              class="p-dialog-header-icon p-dialog-header-maximize p-link"
            >
              <span class="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div>
        </div>
        <zxing-scanner
          #scanner
          [hidden]="!hasDevices"
          [(device)]="currentDevice"
          [formats]="formatsEnabled"
          (scanSuccess)="handleQrCodeResult($event)"
          (permissionResponse)="onHasPermission($event)"
          (camerasFound)="onCamerasFound($event)"
        ></zxing-scanner>

        <div class="p-dialog-footer d-flex justify-content-between">
          <div class="ml-a">
            <button pButton type="button" (click)="onBypassScanAction()" class="p-button-outlined">
              {{ 'dialog.general-delivery.scan.unable-scan' | translate }}
            </button>
          </div>
        </div>

        <ng-container *ngIf="hasPermission === undefined">
          <h2>{{ 'dialog.general-delivery.scan.waiting-permission' | translate }}</h2>
          <p>
            {{ 'dialog.general-delivery.scan.no-camera' | translate }}
          </p>
        </ng-container>

        <ng-container *ngIf="hasPermission === false">
          <h2>{{ 'dialog.general-delivery.scan.deny-permission' | translate }}</h2>
        </ng-container>

        <ng-container *ngIf="hasDevices === false">
          <h2>{{ 'dialog.general-delivery.scan.no-device' | translate }}</h2>
          <p>{{ 'dialog.general-delivery.scan.no-device-attached' | translate }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</form>
